import React, { useState, useEffect } from "react";
import BlogTable from "./BlogTable";
import axios from "api/axiosController";
import { deleteBlog, getBlogs } from "redux/services/blog";
import { Link } from "react-router-dom";
import "./styles.css";

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBlogs();
        setBlogs(response.blogs);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = (id: any) => {
    console.log(`Update blog with ID: ${id}`);
    // To Do: Implement update.
  };

  const handleDelete = async (id: any) => {
    console.log(`Delete blog with ID: ${id}`);
    try {
      const response = await deleteBlog(id);
    } catch (error) {
      console.log(error);
    }
  };

  if (!blogs) {
    return <div>No blogs available</div>;
  }

  return (
    <div>
      <h1>Blog Management</h1>
      <Link to="/blogs/create" className="create-blog-link">
        Create a new blog
      </Link>
      {blogs.length > 0 ? (
        <BlogTable
          blogs={blogs}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
}
