import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

const BlogTable = ({ blogs, onUpdate, onDelete }: any) => {
  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {blogs.map((blog: any) => (
            <TableRow key={blog._id}>
              <TableCell>{blog._id}</TableCell>
              <TableCell>{blog.title}</TableCell>
              <TableCell>{new Date(blog.createdAt).toLocaleString()}</TableCell>
              <TableCell>{blog.createdBy}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onUpdate(blog._id)}
                  sx={{ marginRight: 1 }}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onDelete(blog._id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlogTable;
