import axios from 'api/axiosController';
import { getAccessToken } from "utils/auth";

type HeaderProps = {
    page:number,
    limit:number,
    query?:string,
}

export const getProducts = async (props:HeaderProps) => {
  let uri = `/api/v1/product/allProducts`;
  try {
    
    let headers = {
        "Authorization" : `Bearer ${getAccessToken()}`,
        page: props.page,
        limit: props.limit,
        query: '',
    };

    if(props.query) {
        headers.query = props.query as string;
    }
    if(props.query) {
        headers.query = props.query as string;
    }

    const res = await axios.get(uri, { headers: headers });
    return res.data;
    
} catch (e) {
    throw e;
  }
};
