import './style.css';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Grid,
  Typography,
  TableFooter
} from '@material-ui/core';
import { Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSellerStart } from 'redux/reducers/seller';
import { approveSellerStart, rejectSellerStart } from 'redux/reducers/admin';
import { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '100%'
  },
  tableContainer: {
    borderRadius: 15,
    margin: '10px 10px',
    maxWidth: '100%'
  },
  tableHeaderCell: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light)
  },
  name: {
    fontWeight: 'bold',
    color: theme.palette.secondary.dark
  },
  status: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    color: 'white',
    backgroundColor: 'grey',
    borderRadius: 8,
    padding: '3px 10px',
    display: 'inline-block'
  }
}));

function SellerDashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [data, setData] = React.useState<any>();
  let pageLimit = 10;
  let sellers = useSelector((state: any) => state.seller.sellers);
  const count = useSelector((state: any) => state.seller.count);
  let pageCount: any = Math.ceil(count / pageLimit);
  
  useEffect(() => {
    dispatch(getSellerStart({ page }));
  }, [dispatch]);
  

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getSellerStart({ page:value }));
  };

  const handleSellerApproveReject = (
    email: string,
    sellerFirstName: string,
    name: string
  ) => {
    if (!email && !sellerFirstName) {
      return;
    }
    if (name === 'approveButton')
      dispatch(approveSellerStart({ email, firstName: sellerFirstName }));
    if (name === 'rejectButton')
      dispatch(rejectSellerStart({ email, firstName: sellerFirstName }));
  };

  const handleClick = (seller: any) => {
    setOpen(!open);
    setData(seller)
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4
  };

  const SingleItem = (props:any) => {
 const { seller } = props
    return (
    <>
    <TableRow key={seller._id}>
    {/* <TableCell>
    <Typography color='primary' variant='subtitle2'>
      {sellers.userId}
    </Typography>
  </TableCell> */}
    <TableCell onClick={() => handleClick(seller)}>
      <Grid container>
        <Grid item lg={2}>
          <Avatar
            alt={seller.firstName}
            src='.'
            className={classes.avatar}
          />
        </Grid>
        <Grid item lg={10}>
          <Typography className={classes.name}>
            {seller.firstName}
          </Typography>
          <Typography color='textSecondary' variant='body2'>
            {seller.email}
          </Typography>
        </Grid>
      </Grid>
    </TableCell>
    {/* <TableCell>
    <Typography color='primary' variant='subtitle2'>
      {sellers.sellerId}
    </Typography>
  </TableCell> */}
    <TableCell>{seller.sellerType}</TableCell>
    <TableCell>{seller.approvalStatus}</TableCell>
    <TableCell>
      <Stack direction='row' spacing={2}>
        <Button
                disabled={seller.approvalStatus === "PENDING" ? false : true}

          name='approveButton'
          onClick={() =>
            handleSellerApproveReject(
              seller.email,
              seller.firstName,
              'approveButton'
            )
          }
          variant='contained'
          color='success'
        >
          Approve
        </Button>
        <Button
        disabled={seller.approvalStatus === "PENDING" ? false : true}
          name='rejectButton'
          onClick={() =>
            handleSellerApproveReject(
              seller.email,
              seller.firstName,
              'rejectButton'
            )
          }
          variant='contained'
          color='error'
        >
          Reject
        </Button>
      </Stack>
    </TableCell>
    </TableRow>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        Name: { data?.firstName + ' ' + data?.lastName }<br/>
        Email: { data?.email }<br/>
        City: { data?.city }<br/>
        Approved: { data?.approvalStatus}<br/>
        Email Verified: { data?.isEmailVerified ? 'true' : 'false' }<br/>
        On Boarding Status : { data?.onBoardingStatus }<br/>
        Seller Type: { data?.sellerType }
        <hr/>
        Company Registration
        <hr/>
        DBA: { data?.companyRegistration?.DBA }<br/>
        DUNS: { data?.companyRegistration?.DUNS }<br/>
        Country Of Incorporation: { data?.companyRegistration?.countryOfIncorporation }<br/>
        Legal Company Name: { data?.companyRegistration?.legalCompanyName }<br/>
        Preferred Tax Classifiacation: { data?.companyRegistration?.stateOfIncorporation && data?.companyRegistration?.stateOfIncorporation }<br/>
        Stock Exchange: { data?.companyRegistration?.stockExchange && data?.companyRegistration?.stockExchange ? 'true' : 'false' }<br/>
        <hr/>
        Documents Uploaded:
        <hr/>
          {data?.uploads.map((item:any,index:number)=>(
            <>
            <a href={item.url} target="_blank" >{`Document-${(index+1)}`}</a><br/>
            </>
          ))}
         
          {
            data?.uploads.length === 0?('No Documents uploaded'):null
          }

      </Box>
    </Modal>
    </>
    )
  }

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              {/* <TableCell className={classes.tableHeaderCell}>ID</TableCell> */}
              <TableCell className={classes.tableHeaderCell}>Seller Info</TableCell>
              {/* <TableCell className={classes.tableHeaderCell}>Seller ID</TableCell> */}
              <TableCell className={classes.tableHeaderCell}>Seller Type</TableCell>
              <TableCell className={classes.tableHeaderCell}>Status</TableCell>
              <TableCell className={classes.tableHeaderCell}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sellers.length > 0 &&
              sellers.map((seller: any, index:any) => (<SingleItem key = {index} seller = {seller}/>))}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      <Pagination
        count={pageCount}
        page={page}
        color='primary'
        onChange={handleChangePage}
      />
    </>
  );
}

export default SellerDashboard;
