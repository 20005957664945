import { combineReducers } from '@reduxjs/toolkit';
import admin from './admin';
import user from './user';
import advertiseProducts from './advertiseProducts';
import seller from './seller';
import categories from './categories';
import product from './product';

const rootReducer = combineReducers({
    admin,
    user,
    advertiseProducts,
    seller,
    categories,
    product 
});

export default rootReducer;
