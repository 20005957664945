import { all, fork } from 'redux-saga/effects';
import admin from './adminSaga';
import user from './userSaga';
import advertiseProduct from './advertiseProductSaga';
import seller from './sellerSaga';
import category from './categorySaga';
import product from './productSaga';

export default function* rootSaga() {
  yield all([
    fork (admin),
    fork (user),
    fork (advertiseProduct),
    fork (seller),
    fork (category),
    fork (product)
  ]);
}
