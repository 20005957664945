import Pagination1 from "@mui/material/Pagination";
import { useEffect, useState } from "react";
import { Box, Button, Grid, InputLabel, MenuItem, Modal, Select,
        TextField, SelectChangeEvent, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import product, { getProductStart, updateProducts } from "redux/reducers/product";
import axios from "api/axiosController";
import { Category, Search } from "@material-ui/icons";
import { getAccessToken } from "utils/auth";

type Product = {
    _id: string,
    title: string,
    description: string,
    productgroupimageurl1: string,
    price:number[],
    ListingStatus: string[],
    SellerId: string,
    category:string,
    categoryId:string,
    parentSellerSKU: string
}

type Category = {
    categoryId: string,
    categoryName: string,
    childData?: Category[]
}

type DropDownProps = {
    title:string;
    dropDownData: Category[];
    onMenuChange: (event: SelectChangeEvent<number>) => void,
    selectedValue: number
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const Product = ({productData, onUpdateClick} : any) => {
    const errorimg = "https://m.media-amazon.com/images/I/71AAQp0xwYL._SL1500_.jpg";
    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.style.maxWidth = '100%'; // Set a maximum width to avoid layout issues
        event.currentTarget.src = errorimg; // Provide a fallback image
      };
    return (
        <Box sx={{borderBottom:1, borderColor:"#aaa"}}>
            <div style={{display:"flex", alignItems:"center", marginBottom:"10px"}}>
                <Grid container spacing={2} rowSpacing={5}>

                    {/* Image */}
                    <Grid item xs={2} sm={2} alignItems="center" justifyContent="center">
                        <img height={80} width={80} style={{maxWidth:"80px"}}
                        src={productData.productgroupimageurl1} alt="image"
                        ></img>
                    </Grid>
                    {/* Title */}
                    <Grid item xs={2} sm={2} style={{display:"flex", alignItems:"center"}}>
                        <Typography
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 4,
                            }}
                            style={{fontSize:"12px"}}>
                            {productData.title}
                        </Typography>
                    </Grid>

                    {/* Category */}
                    <Grid item xs={1} sm={1} style={{display:"flex", alignItems:"center"}}>
                        <Typography
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 4,
                            }}
                            style={{fontSize:"12px"}}>
                            {productData.category}
                        </Typography>
                    </Grid>

                    {/* Description */}
                    <Grid item xs={2} sm={2} style={{display:"flex", alignItems:"center"}} >
                        <Typography
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 4,
                            }}
                            style={{fontSize:"11px"}}>
                            {productData.description}
                        </Typography>
                    </Grid>

                    {/* Price */}
                    <Grid item xs={1} sm={1} style={{display:"flex", alignItems:"center"}}>
                        <Typography 
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 4,
                        }}
                        style={{fontSize:"12px"}}>${productData.price[0]}</Typography>
                    </Grid>

                    {/* Listing Status */}
                    <Grid item xs={1} sm={1} style={{display:"flex", alignItems:"center"}}>
                        <Typography
                            style={{fontSize:"11px"}}>
                            {productData.ListingStatus[0]}
                        </Typography>
                    </Grid>

                    {/* Seller ID */}
                    <Grid item xs={1} sm={1} style={{display:"flex", alignItems:"center"}}>
                        <Typography
                            style={{fontSize:"11px"}}>
                            {productData.SellerId}
                        </Typography>
                    </Grid>

                    {/* Update Button */}
                    <Grid item xs={1} sm={1} style={{display:"flex", alignItems:"center"}}>
                        <Button onClick={onUpdateClick}>Update</Button>
                    </Grid>

                </Grid>
            </div>
        </Box>
    );

}

const TableHeader = ({title}:{title:string}) => {
    return(
        <Typography style={{fontSize:"14px", paddingLeft:"3px"}}>
            <Box fontWeight='fontWeightMedium' display='inline'>{title}</Box>
        </Typography>
    )
}

const CategoryDropDown = ({title, dropDownData, onMenuChange, selectedValue} : DropDownProps) => {
    
    return (
        <div style={{display:"flex", flexDirection:"column", width:"100%", marginRight:"10px"}}>
            <InputLabel 
            style={{fontSize:"14px", color:"#000", marginLeft:"5px"}}
            id="category-label">{title}</InputLabel>
            <Select
                onChange={onMenuChange}
                style={{fontSize:"13px", width:"100%", margin:"5px"}}
                labelId="category-label"
                value={selectedValue}
                >

                    {dropDownData.map((category: Category, index:number) => {
                        return (
                            <MenuItem style={{fontSize:"13px"}} 
                            value={index}>{category.categoryName}</MenuItem>
                        );
                    })}
            </Select>
        </div>
    );
}

const ProductsList = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const pageLimit = 100;
    const totalCount = useSelector((state: any) => state.product.count);
    const products = useSelector((state: any) => state.product.products);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedSubCategory, setSelectedSubCategory] = useState(0);
    const [listingStatus, setListingStatus] = useState("");
    const [sQuery, setSQuery] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [prevSearchQuery, setPrevSearchQuery] = useState("");

    // fetch products
    useEffect(() => {
        dispatch(getProductStart({page:currentPage, limit:pageLimit, query:searchQuery}));
    }, [currentPage, searchQuery]);

    // fetch categories
    useEffect(() => {
        const fetchCategories = async () => {
            const uri = "/api/v1/categories";
            const response = await axios.get(uri);
            if(response?.data?.ResponseBody){
                setCategories(response.data.ResponseBody);
                setSelectedCategory(0);
            }
        };

        fetchCategories();
    }, []);

    // Handle update product click
    const handleUpdateClick = (index: number, product:Product) => {
        setSelectedProduct(product);
        setListingStatus(product.ListingStatus[0]);
        setModalOpen(true);
    }

    // Handle parent category changed
    const handleParentCategoryChange = (event: SelectChangeEvent<number>) => {
        const pCatId = event.target.value as number;
        setSelectedCategory(pCatId);
        setSelectedSubCategory(0);
    }

    // Handle sub category changed
    const handleSubCategoryChanged = (event: SelectChangeEvent<number>) => {
        const subCatId = event.target.value as number;
        setSelectedSubCategory(subCatId)
    }

    // Update product API
    const updateProductAPI = async (product: Product) => {
        try {
            
            const uri = "/admin/update-product";
            const headers = {
                "Authorization" : `Bearer ${getAccessToken()}`,
            };
            const body = {
                product:product
            };

            const response = await axios.post(uri, body, {headers:headers});
            if(response.status){
                return response.data;
            }
            else {
                return undefined;
            }

        } catch (error) {
            console.log(error);
            return undefined;
        }
    }

    // Handle Product Update
    const handleProductUpdate = async () => {
        try {
            // If product is selected
            if(selectedProduct){

                // Build updated product
                let updatedProduct: Product = {...selectedProduct};
                
                if(updatedProduct){
                    updatedProduct.ListingStatus = [listingStatus];
                    const newCategory = categories[selectedCategory].childData;
                    if(newCategory) {
                        updatedProduct.category = newCategory[selectedSubCategory].categoryName;
                        updatedProduct.categoryId = newCategory[selectedSubCategory].categoryId; 
                    }
                }
                const response = await updateProductAPI(updatedProduct);
                dispatch(updateProducts({product:updatedProduct}));
            }
            handleModalClose();

        } catch (error) {
            console.log(error);
        }
    }

    // Handle Modal Close
    const handleModalClose = () => {
        setModalOpen(false)
        setSelectedCategory(0);
        setSelectedSubCategory(0);
    }

    // Handle Search Text Change
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            
            setSQuery(event.target.value as string);
            
        } catch (error) {
            console.log(error);
        }
    }

    const handleSearchButtonClick = () => {
        try {

            // Perform search if new query entered
            if(prevSearchQuery !== sQuery) {
                setPrevSearchQuery(searchQuery);
                setSearchQuery(sQuery);
                setCurrentPage(1);
            }
            
        } catch (error) {
            console.log(error);
        }
    }

    return(
        
        <div>

            {/* Products */}
            <div>

                {/* Search Bar */}
                <div style={{display:"flex"}}>
                    <TextField fullWidth label="" 
                    size="small"
                    onChange={handleSearchChange}
                    style={{marginRight:"10px"}}
                    placeholder="Search by Seller ID / Product ID / Product SKU / Title"/>
                    <Button 
                        variant="contained" 
                        onClick={handleSearchButtonClick}>
                        <Search/>
                    </Button>
                </div>

                {/* Product List */}
                <div style={{display:"flex", marginTop:"10px", flexDirection:"column"}}>

                    {/* Table Headers */}
                    <Grid container style={{marginBottom:"10px"}}>
                        <Grid item  xs={2}><TableHeader title="Image"/></Grid>
                        <Grid item  xs={2}><TableHeader title="Title"/></Grid>
                        <Grid item  xs={1}><TableHeader title="Category"/></Grid>
                        <Grid item  xs={2}><TableHeader title="Description"/></Grid>
                        <Grid item  xs={1}><TableHeader title="Price"/></Grid>
                        <Grid item  xs={1}><TableHeader title="Listing Status"/></Grid>
                        <Grid item  xs={1}><TableHeader title="Seller ID"/></Grid>
                        <Grid item  xs={1}><TableHeader title="Action"/></Grid>
                    </Grid>
                    {/* Products */}
                    {products.map((product:Product, index:number) => {
                    
                        return (
                            <Product 
                                productData={product}
                                onUpdateClick={()=>{
                                    handleUpdateClick(index, product);
                                }}
                                />
                        );
        
                    })}
                </div>

                {/* Pagination */}
                <Pagination1
                    style={{marginTop:"10px"}}
                    boundaryCount={9}
                    count={Math.round(totalCount / pageLimit)}
                    page={currentPage}
                    onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                        setCurrentPage(value);    
                }}
                />
            </div>

            {/* Modal */}
            {selectedProduct && <Modal
                open={isModalOpen}
                onClose={handleModalClose}
                >
                <Box sx={modalStyle}>
                    <Grid container>
                        {/* Image */}
                        <Grid item xs={3} >
                            <img 
                                height={120} width={120} 
                                src={selectedProduct.productgroupimageurl1} 
                                alt={selectedProduct.title} />
                        </Grid>

                        {/* Data */}
                        <Grid item xs={9}>
                            {/* Title */}
                            <Typography
                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                }}
                                style={{fontSize:"15px"}}>
                                {selectedProduct.title}
                            </Typography>
                            {/* Price */}
                            <Typography style={{fontSize:"13px", marginTop:"5px"}}>
                                Price: ${selectedProduct.price[0]}
                            </Typography>
                            {/* Parent Seller SKU */}
                            <Typography style={{fontSize:"13px", marginTop:"5px"}}>
                                Parent Seller SKU: {selectedProduct.parentSellerSKU}
                            </Typography>
                            {/* Current Category */}
                            <Typography
                                style={{fontSize:"13px", marginTop:"5px"}}>
                                Current Category: {selectedProduct.category}
                            </Typography>   

                            {/* Category Drop-Down */}
                            { categories &&
                                <div style={{display:"flex", marginTop:"10px"}}>

                                    {/* Parent Category */}
                                    <CategoryDropDown 
                                        title="Parent Category"
                                        onMenuChange={handleParentCategoryChange}
                                        dropDownData={categories as Category[]}
                                        selectedValue={selectedCategory}
                                    />

                                    {/* Sub Category */}
                                    <CategoryDropDown 
                                        title="Sub Category"
                                        dropDownData={categories[selectedCategory].childData as Category[]}
                                        onMenuChange={handleSubCategoryChanged}
                                        selectedValue={selectedSubCategory}
                                    />
                                    
                                </div>
                            }

                            {/* Listing Status Update */}
                            <div style={{display:"flex", flexDirection:"column", marginRight:"10px", marginTop:"10px"}}>
                                <InputLabel 
                                style={{fontSize:"14px", color:"#000", marginLeft:"5px"}}
                                id="category-label">Listing Status</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        setListingStatus(e.target.value);
                                    }}
                                    style={{fontSize:"13px", width:"100%", margin:"5px"}}
                                    labelId="category-label"
                                    value={listingStatus}
                                    >
                                        <MenuItem style={{fontSize:"13px"}} 
                                        value="Live">Live</MenuItem>

                                        <MenuItem style={{fontSize:"13px"}} 
                                        value="NotLive">Not Live</MenuItem>
                                </Select>
                            </div>

                            {/* Update Button */}
                            <div style={{width:"100%", display:"flex", marginTop:"10px", justifyContent:"center"}}>
                                <Button 
                                variant="contained"
                                onClick={handleProductUpdate}
                                >Update</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>}
        </div>
    );
}

export default ProductsList;