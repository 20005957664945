import React, { useState } from "react";
import ReactQuill from "react-quill";
import { postBlogs } from "redux/services/blog";
import "react-quill/dist/quill.snow.css";
import "./styles.css"; // Import the CSS file here

export default function CreateBlog() {
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [image, setImage] = useState("");
  const [errors, setErrors] = useState<string[]>([]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const newErrors: string[] = [];

    if (!title) newErrors.push("Title is required.");
    if (!body) newErrors.push("Body is required.");
    if (!createdBy) newErrors.push("Created By is required.");
    if (!image) newErrors.push("Image is required.");

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("body", body);
    formData.append("createdBy", createdBy);
    formData.append("image", image);

    const formDataEntries = Array.from(formData.entries());
    formDataEntries.forEach(([key, value]) => {
      console.log(key, value);
    });

    try {
      const response = await postBlogs(formData);

      // Reset form
      setTitle("");
      setBody("");
      setCreatedBy("");
      setImage("");
      setErrors([]);
    } catch (error) {
      console.error("Error submitting blog: ", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="page-title">Create a blog</h1>
      <div className="form-row">
        <label htmlFor="title" className="form-label">
          Title
        </label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className="form-row">
        <label htmlFor="createdBy" className="form-label">
          Created By
        </label>
        <input
          type="text"
          id="createdBy"
          value={createdBy}
          onChange={(e) => setCreatedBy(e.target.value)}
        />
      </div>

      <div className="form-row">
        <label htmlFor="image" className="form-label">
          Image
        </label>
        <input
          type="text"
          id="image"
          onChange={(e) => setImage(e.target.value)}
        />
      </div>

      <ReactQuill theme="snow" value={body} onChange={setBody} />

      <button type="submit">Submit this blog</button>

      {errors.length > 0 && (
        <div className="error-messages">
          {errors.map((error, index) => (
            <p key={index} className="error-text">
              {error}
            </p>
          ))}
        </div>
      )}
    </form>
  );
}
