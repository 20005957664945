//formatting the data recieved from algolia so that it becomes similar to the data which we used to get from search io.

export const formattedData = (hitResults: any) => {
  let newhitResults: any = [];
  hitResults.forEach((data: any) => {
    let hitResultsData: any = {};
    hitResultsData.values = data;
    newhitResults.push(hitResultsData);
  });
  return newhitResults;
};
