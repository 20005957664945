import SignIn from "../components/Layout/SiteContent/Signin";
import Home from "../components/Layout/SiteContent/Home";
import { route } from "types";
import ROUTE_PATHS from "./routesPath";
import AdvertiseProducts from "components/Layout/SiteContent/Dashboard/AdvertiseProducts";
import ProductsList from "components/Layout/SiteContent/Dashboard/ProductsList";
import UserList from "components/Layout/SiteContent/Dashboard/UserList";
import Sellers from "components/Layout/SiteContent/Dashboard/SellerList";
import Error from "components/Layout/SiteContent/common/Error";
import { Route } from "react-router-dom";
import Blog from "components/Layout/SiteContent/Blog";
import CreateBlog from "components/Layout/SiteContent/Blog/create";

const routes: route[] = [
  {
    path: ROUTE_PATHS.DASHBOARD,
    component: <Home />,
  },
  {
    path: ROUTE_PATHS.SIGNIN,
    component: <SignIn />,
  },
  {
    path: ROUTE_PATHS.SELLERS,
    component: <Sellers />,
  },
  {
    path: ROUTE_PATHS.USERS,
    component: <UserList />,
  },
  {
    path: ROUTE_PATHS.ADVERTISE,
    component: <AdvertiseProducts />,
  },
  {
    path: ROUTE_PATHS.PRODUCTS,
    component: <ProductsList />,
  },
  {
    path: ROUTE_PATHS.BLOG,
    component: <Blog />,
  },
  {
    path: ROUTE_PATHS.CREATE_BLOGS,
    component: <CreateBlog />,
  },
  {
    path: ROUTE_PATHS.ERROR,
    component: <Error />,
  },
];

export default routes;
