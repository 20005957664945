import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  error: boolean; 
  products: any[];
  count: number;
}

const initialState: State = {
  isLoading: true,
  error: false,
  products: [],
  count: 0
};

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
      getProductStart: (state: State, action) => ({
        ...state,
        isLoading: true
      }),
      getProductSuccess: (state: State, action) => ({
        ...state,
        isLoading: false,
        products: action.payload.products,
        count: action.payload.count
      }),
      getProductFailure: (state: State, action) => ({
        ...state,
        error: true,
        isLoading: false
      }),
      updateProducts: (state: State, action) => {
        const updatedProduct = action.payload.product;
        const index = state.products.findIndex((product) => product._id === updatedProduct._id)
        if(index !== -1 && updatedProduct) {
          state.products[index] = updatedProduct;
        }
      }
    }
  });
  
  export const { getProductStart, getProductSuccess, getProductFailure, updateProducts } = productSlice.actions;
  export default productSlice.reducer;