import { call, put, takeLatest } from 'redux-saga/effects';
import snack from 'components/wrapper/snack';
import { getProductStart, getProductSuccess, getProductFailure } from 'redux/reducers/advertiseProducts';
import { getProducts } from 'redux/services/advertiseProduct';

function* productSagaWatcher() {
  yield takeLatest([getProductStart.type], productWorker);
}

function* productWorker(action: any): any {
  try {
    switch (action.type) {
      case getProductStart.type: {
        const response: any = yield call(getProducts, action.payload.page);
        yield put(
          getProductSuccess({
            products: response.ResponseBody.products,
            count: response.ResponseBody.count
          })
        );
        break;
      }

      default:
        break;
    }
  } catch (err: any) {
    console.error(`Error occuring while calling an action ${action.type}`, err);
    if (action.type === getProductStart.type) {
      console.error(err?.response?.data?.msg);
      yield put(getProductFailure({ msg: err?.response?.data?.msg }));
    }
  }
}

export default productSagaWatcher;
