//Define type of route

const ROUTE_PATHS = {
  SIGNIN: "/signin",
  DASHBOARD: "/",
  SELLERS: "/sellers",
  USERS: "/users",
  ADVERTISE: "/advertise",
  PRODUCTS: "/products",
  BLOG: "/blogs",
  CREATE_BLOGS: "/blogs/create",
  ERROR: "/error",
};

export default ROUTE_PATHS;
