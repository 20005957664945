import { Router } from "./routes";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import algoliasearch from "algoliasearch";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";

import "./styles/common.css";

export const searchClient = algoliasearch(
  "ZYWA1Y4MN0",
  "303b417ecb69d27feb1514c6876e2cd7"
);
export const searchClientIndex = searchClient.initIndex("paysfer");

const App = () => {
  return (
    <Provider store={store}>
      <InstantSearch searchClient={searchClient} indexName="paysfer">
        <Configure hitsPerPage={100} />
        <Router />
      </InstantSearch>
    </Provider>
  );
};

export default App;
