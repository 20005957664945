import { Routes, Route } from "react-router-dom";
import routes from "./routeList";
import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import {
  ListItemText,
  ListItemIcon,
  ListItem,
  Divider,
  List,
  Toolbar,
  AppBar,
  CssBaseline,
  Drawer,
  Box,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import snack from "components/wrapper/snack";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SellIcon from "@mui/icons-material/Sell";
import { adminSignOutStart } from "redux/reducers/admin";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupIcon from "@mui/icons-material/Group";
import SignIn from "components/Layout/SiteContent/Signin";
import ArticleIcon from "@mui/icons-material/Article"; // Import the icon

import { fetchCategoriesStart } from "redux/reducers/categories";

const drawerWidth = 240;

const CustomRoutes: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state: any) => state.admin.accessToken);

  useEffect(() => {
    if (!accessToken) {
      navigate("/signin");
      snack.error("Please Sign in to Continue");
    }
    dispatch(fetchCategoriesStart());
  }, []);

  const onSignOut = () => {
    dispatch(adminSignOutStart({ navigate }));
  };

  return (
    <>
      {!accessToken ? (
        <Routes>
          <Route path={"/signin"} key={"/signin"} element={<SignIn />} />
        </Routes>
      ) : (
        <>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
              position="fixed"
              sx={{
                width: `calc(100% - ${drawerWidth}px)`,
                ml: `${drawerWidth}px`,
              }}
            >
              <Toolbar>
                <Typography variant="h6" noWrap component="div">
                  Paysfer Admin Dashboard
                </Typography>
              </Toolbar>
            </AppBar>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
              variant="permanent"
              anchor="left"
            >
              <Toolbar />
              <Divider />
              <List>
                <ListItem
                  button
                  key="Sellers"
                  onClick={() => navigate("/sellers")}
                >
                  <ListItemIcon>
                    <StorefrontIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sellers" />
                </ListItem>
                <ListItem button key="Users" onClick={() => navigate("/users")}>
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
                <ListItem
                  button
                  key="Advertise"
                  onClick={() => navigate("/advertise")}
                >
                  <ListItemIcon>
                    <SellIcon />
                  </ListItemIcon>
                  <ListItemText primary="Advertise" />
                </ListItem>

                <ListItem
                  button
                  key="Products"
                  onClick={() => navigate("/products")}
                >
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Products" />
                </ListItem>

                <ListItem button key="Blogs" onClick={() => navigate("/blogs")}>
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Blogs" />
                </ListItem>

                <ListItem button key="Sign Out" onClick={onSignOut}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sign Out" />
                </ListItem>
              </List>
            </Drawer>
            <Box
              component="main"
              sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
            >
              <Toolbar />
              <Routes>
                {routes.map(({ path, ...rest }) => (
                  <Route path={path} key={path} element={rest.component} />
                ))}
              </Routes>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default CustomRoutes;
