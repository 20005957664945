// @ts-nocheck
// @ts-ignore
import { useSearchBox, usePagination } from "react-instantsearch-hooks-web";
import { useHits } from "react-instantsearch-hooks-web";
import type { UseSearchBoxProps } from "react-instantsearch-hooks-web";
import { Combobox } from "@sajari/react-components";
import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Pagination1 from "@mui/material/Pagination";
import { Button, Chip } from "@mui/material";
import axios from "api/axiosController";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { formattedData } from "utils/formatData";

const queryHook: UseSearchBoxProps["queryHook"] = (query, search) => {
  // console.log(search, "check queryhook search here!!");
  search(query);
}; // will integrate later

const AdvertiseProducts = () => {
  const [results, setHitResultsData] = useState<any>([]);
  const { query, refine, clear, isSearchStalled } = useSearchBox({ queryHook });
  const { hits, results: hitResults } = useHits();
  const { refine: setPage } = usePagination();
  let categoriesData = useSelector(
    (state: RootState) => state.categories.categoryList
  );
  const [resultItems, setResultItems] = useState([]);
  const { nbHits: totalResults }: any = hitResults;
  const [paginationPage, setPaginationPage] = React.useState(1);
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      width: 125,
      minWidth: 400,
      maxWidth: 450,
    },
    {
      field: "description",
      headerName: "Description",
      width: 125,
      minWidth: 400,
      maxWidth: 450,
    },

    {
      field: "saleprice",
      headerName: "Price",
      type: "number",
    },
  ];
  const postAddss = async (data: any[]) => {
    let uri = `/googleAdd/postAdds`;
    data.forEach(async (item) => {
      try {
        const res = await axios.post(uri, item);
        return res.data;
      } catch (e) {
        throw e;
      }
    });
  };
  const titelize = (title: string) => {
    return title
      .replace(/[^a-zA-Z ]/g, "")
      .replace(/ /g, "_")
      .replace(/ *\([^)]*\) */g, "")
      .replace(/[()\\\/]/g, "");
  };

  const getCategory = (categoryValue: string) => {
    let categoryNameValue = categoryValue;
    if (!!categoryValue) {
      categoriesData.forEach((item: any) => {
        if (item.categoryName === categoryValue) {
          categoryNameValue = item.categoryId;
        } else {
          item.childData.forEach((ele: any) => {
            if (ele.categoryName === categoryValue) {
              categoryNameValue = ele.categoryId;
            }
            if (ele.categoryId === categoryValue) {
              categoryNameValue = ele.categoryId;
            }
          });
        }
      });
    }
    return categoryNameValue;
  };

  const postAdds = () => {
    let data: any[] = [];
    selectedItems.forEach((item) => {
      let updatedData = {};
      let product = {
        offerId: item.id,
        title: item.title,
        description: item.description,
        brand: item.brand,
        contentLanguage: "en",
        targetCountry: "US",
        link: `https://paysfer.com/product-page/${item.id}/${titelize(
          item.title
        )}`,
        imageLink: item.productgroupimageurl1,
        channel: "online",
        availability: "in stock",
        googleProductCategory:
          item.categoryId && item.categoryId
            ? item.categoryId
            : getCategory(item.category),
        price: {
          value: item.price[0],
          currency: "USD",
        },
      };
      updatedData = { ...product };
      if (
        item.saleprice &&
        item.saleprice.length &&
        item.saleprice.length[0] !== null
      ) {
        updatedData = {
          ...updatedData,
          price: {
            value: item.saleprice[0],
            currency: "USD",
          },
        };
      }
      if (
        item.gtin &&
        item.gtin.length &&
        !item.gtin.includes(null) &&
        !item.gtin.includes("")
      ) {
        updatedData = { ...updatedData, gtin: item.gtin[0] };
      } else {
        if (item.gtin && item.gtin.length > 1) {
          updatedData = { ...updatedData, gtin: item.gtin[1] };
        }
      }
      if (
        item.mpn &&
        item.mpn.length &&
        !item.mpn.includes(null) &&
        !item.mpn.includes("")
      ) {
        updatedData = { ...updatedData, mpn: item.mpn[0] };
      }
      if (
        item.condition &&
        (item.condition.toUpperCase() === "REFURBISHED" ||
          item.condition.toUpperCase() === "NEW")
      ) {
        updatedData = {
          ...updatedData,
          condition:
            item.condition.toUpperCase() === "REFURBISHED"
              ? "refurbished"
              : "new",
        };
      }
      data.push({ ...updatedData });
    });
    postAddss(data);
  };

  useEffect(() => {
    // alert("called");
    setHitResultsData(formattedData(hits));
    //Need to do changes here!!!!
    // console.log(hitResultsData, "Hit Results Data check here");
  }, [hits]);

  useEffect(() => {
    if (results.length > 0) {
      let data: any[] = [];
      results.forEach((item) => {
        data.push(item.values);
      });
      setResultItems(data as any);
    }
  }, [results]);
  // useEffect(() => {
  //   setResultsPerPage(100);
  // }, []);

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          position: "fixed",
          width: "80%",
          zIndex: "10000",
        }}
      >
        <Combobox onChange={(value: any) => refine(value)} />
      </div>
      <br /> <br /> <br />
      <div style={{ display: "flex" }}>
        <div style={{ height: 500, width: "50%", overflow: "scroll" }}>
          <DataGrid
            rows={resultItems}
            onPageSizeChange={(newPage) => {}}
            columns={columns}
            autoHeight={true}
            checkboxSelection
            hideFooter={true}
            onSelectionModelChange={(selectionModel) => {
              let allValue: any[] = [];
              selectionModel.forEach((ele) => {
                let data = resultItems.filter((item: any) => item.id === ele);
                allValue.push(data[0]);
              });
              allValue.forEach((item: any) => {
                let flag = true;
                selectedItems.forEach((ele: any) => {
                  if (item.id === ele.id) {
                    flag = false;
                  }
                });
                if (flag) {
                  setSelectedItems([...selectedItems, item]);
                }
              });
            }}
          />
        </div>
        &nbsp;
        <div style={{ height: 500, width: "50%", overflow: "scroll" }}>
          <h1>Selected Products</h1>
          <br />
          {selectedItems.map((item: any) => (
            <div key={item.toString()}>
              <Chip
                key={item.toString()}
                label={item.title}
                onDelete={() => {
                  let clonedArray = JSON.parse(JSON.stringify(selectedItems));
                  let index = 0;
                  clonedArray.forEach((element: any, i: number) => {
                    if (element.id === item.id) {
                      index = i;
                    }
                  });
                  clonedArray.splice(index, 1);
                  setSelectedItems(JSON.parse(JSON.stringify(clonedArray)));
                }}
              />
              <br />
              <br />
            </div>
          ))}
        </div>
      </div>
      <br />
      <Pagination1
        count={Math.round(totalResults / 100)}
        page={paginationPage}
        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
          // showMore();
          setPage(value - 1);
          setPaginationPage(value);
        }}
      />
      <Button
        color="secondary"
        style={{ float: "right", position: "relative", bottom: "24px" }}
        onClick={() => postAdds()}
      >
        Post Adds
      </Button>
    </div>
  );
};

export default AdvertiseProducts;
