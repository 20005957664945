import axios from "api/axiosController";
import { getAccessToken } from "utils/auth";

export const getBlogs = async () => {
  let url = "/api/v1/blogs";
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const postBlogs = async (data: any) => {
  let url = "/api/v1/blogs";

  const newData = {
    title: data.get("title"),
    body: data.get("body"),
    createdBy: data.get("createdBy"),
    image: data.get("image"),
  };

  try {
    const res = await axios.post(url, newData, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });

    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteBlog = async (id: any) => {
  const url = `/api/v1/blogs/${id}`;

  try {
    const res = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });

    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
